import React from 'react';
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";

const Lage = () => {
  return (
      <Layout>
        <Seo title="Lage der Naturspielgruppe Wakiti in Uster" />
        <div className="content">
          <div className='title'><h1>Lage</h1></div>
          <div>
          <p>Der Treffpunkt der Spielgruppe ist auf dem Hof Neufuhr in Uster. Vis à vis befindet sich die Bushaltestelle Neufuhr. Wenn Sie mit dem Auto unterwegs sind, können Sie die Kiesauffahrt nehmen und auf diesem Platz parken. Sowohl der Start als auch der Abschluss befindet sich auf der eingezäunten Wiese links vom Hof. Trotz der angrenzenden befahrenen Strasse können wir so die Sicherheit während des Ankommens und der Verabschiedung gewährleisten. Viel Zeit dürfen wir zudem im nahe gelegenen Wald verbringen.</p>
          <StaticImage
                  src="../images/lage.jpg"
                  quality={95}
                  formats={["auto", "webp", "avif"]}
                  alt="Jill Zimmermann"
                  style={{ marginBottom: `1.45rem` }}
                />
          </div>
          <p>Sulzbacherstrasse 81, 8610 Uster</p>
        </div>
      </Layout>
  )
}

export default Lage;